import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';

import Layout from './layout';
// import css from './index.module.scss';


const roundUpHour = (date) => {
	date.setHours(date.getHours() + 1, 0,0,0);	
	return date.getTime();
}


const Wrapper = ({ data, children }) => {
	const [ priceTab, setPriceTab ] = useState(null);
	const [ actualPrice, setActualPrice ] = useState(null);	
	
	// const changePriceZone = timeRemaining => setTimeout(() => {
	// 	let d = new Date();		

	// 	// restart timer
	// 	timeRemaining = roundUpHour(new Date()) - d.getTime();
	// 	console.log((timeRemaining /3600000 ) * 60, changePriceZone);
	// 	changePriceZone(timeRemaining);
		
	// 	// set proper pricing tab
	// 	priceIntervals.some((e,i) => {
	// 		if(e <= d.getHours()) {
	// 			setPriceTab(i);
	// 			return true
	// 		}
	// 	});
		
	// }, timeRemaining + 500)
	

	useEffect(() => {
      const priceIntervals = [0,7,20];	
      let timeRemaining = 1;
      const changePriceZone = delay => setTimeout(() => {
         let d = new Date();
         // restart timer
         timeRemaining = roundUpHour(new Date()) - d.getTime();
         changePriceZone(timeRemaining);
         
         // set proper pricing tab
         priceIntervals.reverse().some((e) => {
            if(e <= d.getHours()) {
               let i = priceIntervals.reverse().indexOf(e);
               setPriceTab(i);
               setActualPrice(i);
               return true
            } else {
               return false
            }
         });
      }, delay)

		// set actual price tab
		changePriceZone(timeRemaining);		
	}, [])	
	
	return (
		<Layout>
			{children}
         <div id="uvod" className="container pt-5">
				<div className="row mb-5 pb-lg-3 counters">
					<div className="col-lg-10 text-center offset-lg-1">
						<h1 className="font-weight-bold text-9 mb-0">
							Zámkařství Praha s.r.o.<br /> Zámkařské a zámečnické práce
						</h1>
						<p className="sub-title text-primary text-4 font-weight-semibold heading heading-border heading-middle-border heading-middle-border-center mt-2 mb-4 ">
							<span className="headingBorder">
								VAŠE CESTA K{' '}
								<span className="highlighted-word alternative-font-4 font-weight-semibold line-height-2 pb-2">
									PERFEKTNÍMU ZABEZPEČENÍ
								</span>
							</span>
						</p>
						{/* <p className="text-1rem text-color-default negative-ls-05 pb-4 mb-5 ">
							Zámkařství Praha s.r.o Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda id at
							asperiores sit est, voluptate atque? Ullam facere aut commodi voluptatibus omnis! Cum, commodi
							alias quidem non esse recusandae. Consequuntur.
						</p> */}
						<Img
							fluid={data.img1.childImageSharp.fluid}
							alt="Zámkařské práce"
							className="decorationImg"							
						/>
					</div>
				</div>
			</div>
			<section id="sluzby">
				<div className="container">
					<div className="row mb-5">
						<div className="col-md-12 align-self-center p-static text-center">
							<h2 className="text-10 mb-0">
								Zámkařské & Zámečnické <strong>Služby</strong>
							</h2>
							<span className="sub-title">S čím Vám můžeme pomoci</span>
							
							<div className='feature-box align-items-center justify-content-center pt-2'>
										<div className='d-inline-flex'>
											<a href={'tel:+420' + data.site.siteMetadata.tel}>
												<i
													className='fab fa-whatsapp text-7 p-relative'
													style={{ top: '-2px' }}
												/>
											</a>
										</div>
										<div className='d-inline-flex'>
											<p className='pb-0 ml-2 mb-2 font-weight-semibold line-height-5 text-3'>
												<a href={'tel:+420' + data.site.siteMetadata.tel}>
													(+420) {data.site.siteMetadata.tel}
												</a>
											</p>
										</div>
									</div>							
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-4">
							<h4 className="text-uppercase"><Link to='zamcene-a-zabouchnute-dvere'>Zamčené a zabouchnuté dveře</Link></h4>
							<ul className="list list-icons">
								<li>
									<i className="fas fa-check" /> Otevření zabouchnutých dveří bez poškození
								</li>
								<li>
									<i className="fas fa-check" /> Fixní ceny
								</li>
								<li>
									<i className="fas fa-check" /> Rychlé a fér jednání
								</li>
							</ul>
							<p>
								<strong>Vlastník</strong>: Požadujeme doklad totožnosti + nahlédnutí do katastru nemovitostí.{' '}
								<br />
								<strong>Nájemce</strong>: Požadujeme nájemní slouvu + asistenci vlastníka / policie ČR.</p>
                     <p>
                        <AnchorLink onClick={() => setPriceTab(actualPrice)} href='#cenik'><strong>Aktuální ceny <i className="fas fa-chevron-down"></i></strong></AnchorLink>
							</p>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4 mb-4">
							<h4 className="text-uppercase">Vložky a zámky</h4>
							<ul className="list list-icons">
								<li>
									<i className="fas fa-check" /> Výměna standardních i lištových zámků
								</li>
								<li>
									<i className="fas fa-check" /> Sjednocení vložek
								</li>
								<li>
									<i className="fas fa-check" /> Dle aktuální úrovně, nebo žádosti na zvýšení třídy zabezpečení
								</li>
							</ul>
							<p>
							Kvalita zabezpečení dveří vložkou je často nejslabším článkem na úrovni zabezpečení objektu. Pomůžeme Vám ji zvýšit. <AnchorLink onClick={() => setPriceTab(actualPrice)} href='#cenik'><strong>Aktuální ceny <i className="fas fa-chevron-down"></i></strong></AnchorLink>
							</p>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4 mb-4">
							<h4 className="text-uppercase">Instalace a výměna kování</h4>
							<ul className="list list-icons">
								<li>
									<i className="fas fa-check" /> Výměna starého, či instalace nového kování
								</li>
								<li>
									<i className="fas fa-check" /> Dle třídy zabezpečení stávajících dveří
								</li>
								<li>
									<i className="fas fa-check" /> Dle požadavku na zvýšení třídy zabezpečení
								</li>
							</ul>
							<p>
								<AnchorLink onClick={() => setPriceTab(actualPrice)} href='#cenik'><strong>Aktuální ceny <i className="fas fa-chevron-down"></i></strong></AnchorLink>
							</p>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4 mb-4">
							<h4 className="text-uppercase">Vyjmutí zalomeného klíče</h4>
							<ul className="list list-icons">
								<li>
									<i className="fas fa-check" /> Poradíme si s libovolným zámkem
								</li>
								<li>
									<i className="fas fa-check" /> Klíč vyndáme bez poškození zámku
								</li>
							</ul>
							<p>Zalomení klíče je velmi častý problém, se kterým se potýkáme denně. <AnchorLink onClick={() => setPriceTab(actualPrice)} href='#cenik'><strong>Aktuální ceny <i className="fas fa-chevron-down"></i></strong></AnchorLink></p>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4 mb-4">
							<h4 className="text-uppercase">Systémy generálního klíče</h4>
							<ul className="list list-icons">
								<li>
									<i className="fas fa-check" /> Na míru dle vašich požadavků
								</li>
								<li>
									<i className="fas fa-check" /> Generální, skupinové i vlastní klíče
								</li>
							</ul>
							<p>
								Systém generálního klíče umožňuje řídit přístupy do jednotlivých dveří pro jednotlivce i
								skupiny. Generální klíč odemkne všechny dveře, ostatní dle nastavení systému. <AnchorLink onClick={() => setPriceTab(actualPrice)} href='#cenik'><strong>Aktuální ceny <i className="fas fa-chevron-down"></i></strong></AnchorLink>
							</p>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4 mb-4">
							<h4 className="text-uppercase">Další služby</h4>
							<p>
								Poradíme si i s <strong>trezory</strong>, <strong>poštovními schránkami</strong>, nebo{' '}
								<strong>visacími zámky</strong> <AnchorLink onClick={() => setPriceTab(actualPrice)} href='#cenik'><strong>Aktuální ceny <i className="fas fa-chevron-down"></i></strong></AnchorLink>
							</p>
						</div>
					</div>
				</div>
			</section>
			<section id="reference" className="section bg-color-grey-scale-1 section-height-3 border-0 m-0 pb-0">
				<div className="container pb-2">
					<div className="row">
						<div className="col-lg-6 text-center text-md-left mb-5 mb-lg-0">
							<h2 className="text-color-dark font-weight-normal text-6 mb-2">
								Naše <strong className="font-weight-extra-bold">Reference</strong>
							</h2>
							<p className="lead">
								Každý zákazník je pro nás důležitý a jsme rádi, pokud je s našimi službami spokojen
							</p>
							<div className="row justify-content-center mt-2">
								<div className="col-5 text-center col-md-3">
									<Img
										fluid={data.logo1.childImageSharp.fluid}
										alt="CISA logo"
										className="img-fluid hover-effect-3"
									/>
								</div>
								<div className="col-5 text-center col-md-3 my-3 my-md-0">
									<Img
										fluid={data.logo2.childImageSharp.fluid}
										alt="EVVA logo"
										className="img-fluid hover-effect-3"
									/>
								</div>
								<div className="col-5 text-center col-md-3">
									<Img
										fluid={data.logo3.childImageSharp.fluid}
										alt="FAB logo"
										className="img-fluid hover-effect-3"
									/>
								</div>
								<div className="col-5 text-center col-md-3">
									<Img
										fluid={data.logo4.childImageSharp.fluid}
										alt="FAB logo"
										className="img-fluid hover-effect-3"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="testimonial testimonial-style-2 testimonial-with-quotes testimonial-quotes-dark testimonial-remove-right-quote pl-md-4 mb-4">
								<div className="testimonial-author">
									<p className="filled-stars d-flex justify-content-center mb-3">
										<span className="star  text-7 text-primary">
											<i className="fas fa-star" />
										</span>
										<span className="star  text-7 text-primary">
											<i className="fas fa-star" />
										</span>
										<span className="star  text-7 text-primary">
											<i className="fas fa-star" />
										</span>
										<span className="star  text-7 text-primary">
											<i className="fas fa-star" />
										</span>
										<span className="star  text-7 text-primary">
											<i className="fas fa-star" />
										</span>
									</p>
								</div>
								<blockquote>
									<p className="text-color-dark text-4 line-height-5 mb-0">
									Děkuji za rychlou a profesionální práci! Stál jsem před bytem a nevěděl jak dál. Pán dorazil za chvíli, okoukl situaci a seznámil mě s ceníkem. Pak už to byla rychlovka."</p>
								</blockquote>
								<div className="testimonial-author">
									<p>
										<strong className="font-weight-extra-bold text-2">Petr D.</strong>
										<span>Praha 8</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-primary border-top-0 mb-0 py-5">
					<div className="container">
						<div className="row counters counters-sm counters-text-light">
							<div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
								<div className="counter">
									<strong data-to="14000" data-append="+">
										14000+
									</strong>
									<label className="opacity-5 text-4 mt-0 text-white">Spokojených klientů</label>
								</div>
							</div>
							<div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
								<div className="counter">
									<strong data-to="4200" data-append="+">
										4200+
									</strong>
									<label className="opacity-5 text-4 mt-0 text-white">Otevřených dveří</label>
								</div>
							</div>
							<div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
								<div className="counter">
									<strong data-to="20" data-append="min">
										20min
									</strong>
									<label className="opacity-5 text-4 mt-0 text-white">Průměrný čas dojezdu </label>
								</div>
							</div>
							<div className="col-sm-6 col-lg-3">
								<div className="counter">
									<strong data-to="24" data-append="h">
										24h
									</strong>
									<label className="opacity-5 text-4 mt-0 text-white">Denně</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="cenik">
				<div className="container pt-5">
					<div className="row mb-5">
						<div className="col-md-12 align-self-center p-static text-center">
							<h2 className="text-10 mb-0">
								<strong>Ceník</strong> služeb
							</h2>
							<span className="sub-title">Přijímáme platby kartou i v hotovosti</span>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<div className="tabs tabs-bottom tabs-center tabs-simple">
								<ul className="nav nav-tabs">
									<li className={priceTab === 0 ? 'nav-item active' : 'nav-item'}>
										<button className="nav-link" data-toggle="tab" onClick={() => setPriceTab(0)}>
											00:00 - 07:00
										</button>
									</li>
									<li className={priceTab === 1 ? 'nav-item active' : 'nav-item'}>
										<button className="nav-link" onClick={() => setPriceTab(1)} data-toggle="tab">
											07:00 - 20:00
										</button>
									</li>
									<li className={priceTab === 2 ? 'nav-item active' : 'nav-item'}>
										<button
											className="nav-link"
											onClick={() => setPriceTab(2)}
											data-toggle="tab"
										>
											20:00 - 00:00
										</button>
									</li>
								</ul>
								<div className="tab-content">
									<div className={priceTab === 0 ? 'tab-pane active' : 'tab-pane'} id="cenyrano">
										<div className="text-center">
											<h4>Ranní tarif {actualPrice === 0 ? <><br /><small>( aktuální )</small></> : null}</h4>
											<div className="row">
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Fixní cena</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Zabouchnuté dveře</td>
																<td>ANO</td>
																<td>1800 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté plastové, bezpečnostní</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno tvarový zámek</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno standart, přídavný zámek</td>
																<td>ANO</td>
																<td>2300 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno dozický zámek</td>
																<td>ANO</td>
																<td>2300 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno vložka proti odvrtání, přídavný zámek</td>
																<td>ANO</td>
																<td>2600 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno bezpečnostní kování, bezpečnostní dveře</td>
																<td>ANO</td>
																<td>3000 Kč</td>
															</tr>
															<tr>
																<td>Trezorový systém</td>
																<td>ANO</td>
																<td>3500 Kč</td>
															</tr>
															<tr>
																<td>Zamčený visací, poštovní, nebo skříňkový zámek</td>
																<td>ANO</td>
																<td>1400 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté dveře 3x střelka</td>
																<td>ANO</td>
																<td>2500 Kč</td>
															</tr>
															<tr>
																<td>Prasklá střelka</td>
																<td>ANO</td>
																<td>3000 Kč</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>Fixní cena je včetně všech režií a výjezdu </em>
													</p>
												</div>
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Výjezd</td>
																<td>800 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy do 10 km 200 Kč </td>
																<td>400 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy nad 10 km</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Odstranění vložky bez klíče</td>
																<td>800 Kč *</td>
															</tr>
															<tr>
																<td>Vyjmutí zalomeného klíče</td>
																<td>1000 Kč *</td>
															</tr>
															<tr>
																<td>Výměna zámku, vložky a přídavného zámku</td>
																<td>400 Kč *</td>
															</tr>
															<tr>
																<td>Montáž, demontáž, kování</td>
																<td>400 Kč *</td>
															</tr>
															<tr>
																<td>Marný výjezd (bez zásahu)</td>
																<td>700 Kč</td>
															</tr>
															<tr>
																<td>Otevření trezoru</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Hodina práce</td>
																<td>700 Kč</td>
															</tr>
															<tr>
																<td>Zaseklá závora, rozvory, trny</td>
																<td>Dle dohody</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>* nutno započítat výjezd technika </em>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className={priceTab === 1 ? 'tab-pane active' : 'tab-pane'} id="cenyden">
										<div className="text-center">
											<h4>Denní tarif {actualPrice === 1 ? <small>aktuální</small> : null}</h4>
											<div className="row">
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Fixní cena</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Zabouchnuté dveře</td>
																<td>ANO</td>
																<td>1000 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté plastové, bezpečnostní</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno tvarový zámek</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno standart, přídavný zámek</td>
																<td>ANO</td>
																<td>1500 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno dozický zámek</td>
																<td>ANO</td>
																<td>1500 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno vložka proti odvrtání, přídavný zámek</td>
																<td>ANO</td>
																<td>1800 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno bezpečnostní kování, bezpečnostní dveře</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Trezorový systém</td>
																<td>ANO</td>
																<td>3500 Kč</td>
															</tr>
															<tr>
																<td>Zamčený visací, poštovní, nebo skříňkový zámek</td>
																<td>ANO</td>
																<td>1000 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté dveře 3x střelka</td>
																<td>ANO</td>
																<td>1500 Kč</td>
															</tr>
															<tr>
																<td>Prasklá střelka</td>
																<td>ANO</td>
																<td>1800 Kč</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>Fixní cena je včetně všech režií a výjezdu </em>
													</p>
												</div>
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Výjezd</td>
																<td>500 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy do 10 km 200 Kč </td>
																<td>200 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy nad 10 km</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Odstranění vložky bez klíče</td>
																<td>500 Kč *</td>
															</tr>
															<tr>
																<td>Vyjmutí zalomeného klíče</td>
																<td>500 Kč *</td>
															</tr>
															<tr>
																<td>Výměna zámku, vložky a přídavného zámku</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Montáž, demontáž, kování</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Marný výjezd (bez zásahu)</td>
																<td>500 Kč</td>
															</tr>
															<tr>
																<td>Otevření trezoru</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Hodina práce</td>
																<td>500 Kč</td>
															</tr>
															<tr>
																<td>Zaseklá závora, rozvory, trny</td>
																<td>Dle dohody</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>* nutno započítat výjezd technika </em>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className={priceTab === 2 ? 'tab-pane active' : 'tab-pane'} id="cenyvecer">
										<div className="text-center">
											<h4>Večerní tarif {actualPrice === 2 ? <small>aktuální</small> : null}</h4>
											<div className="row">
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Fixní cena</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
														<tr>
																<td>Zabouchnuté dveře</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté plastové, bezpečnostní</td>
																<td>ANO</td>
																<td>1400 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno tvarový zámek</td>
																<td>ANO</td>
																<td>1400 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno standart, přídavný zámek</td>
																<td>ANO</td>
																<td>1700 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno dozický zámek</td>
																<td>ANO</td>
																<td>1700 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno vložka proti odvrtání, přídavný zámek</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno bezpečnostní kování, bezpečnostní dveře</td>
																<td>ANO</td>
																<td>2400 Kč</td>
															</tr>
															<tr>
																<td>Trezorový systém</td>
																<td>ANO</td>
																<td>3500 Kč</td>
															</tr>
															<tr>
																<td>Zamčený visací, poštovní, nebo skříňkový zámek</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté dveře 3x střelka</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Prasklá střelka</td>
																<td>ANO</td>
																<td>2400 Kč</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>Fixní cena je včetně všech režií a výjezdu </em>
													</p>
												</div>
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
														<tr>
																<td>Výjezd</td>
																<td>600 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy do 10 km 200 Kč </td>
																<td>300 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy nad 10 km</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Odstranění vložky bez klíče</td>
																<td>700 Kč *</td>
															</tr>
															<tr>
																<td>Vyjmutí zalomeného klíče</td>
																<td>700 Kč *</td>
															</tr>
															<tr>
																<td>Výměna zámku, vložky a přídavného zámku</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Montáž, demontáž, kování</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Marný výjezd (bez zásahu)</td>
																<td>600 Kč</td>
															</tr>
															<tr>
																<td>Otevření trezoru</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Hodina práce</td>
																<td>600 Kč</td>
															</tr>
															<tr>
																<td>Zaseklá závora, rozvory, trny</td>
																<td>Dle dohody</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>* nutno započítat výjezd technika </em>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<AnchorLink href="#header" className="scroll-to-top visible"><i className="fas fa-chevron-up"></i></AnchorLink>
		</Layout>
	);
};

export default Wrapper;