import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Wrapper from '../components/Layouts/wrapper';
// import css from './index.module.scss';

const IndexPage = ({data}) => {
	return (
		<Wrapper data={data}>
			<div id="hero" className="hero">
				<Img
					fluid={data.img2.childImageSharp.fluid}
					alt="Background"
					className="opacity-9"
					style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%' }}
				/>
				<div className="container">
					<div className="row align-items-center pt-3">
						<div className="col-lg-6 mb-sm-5">
							{/* <p
								className="text-primary font-weight-bold mb-1 appear-animation animated fadeInUpShorter appear-animation-visible"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-duration="750"
								style={{ animationDelay: '100ms' }}
							>
								Co je zamčené.. otevřeme<br />Co je otevřené.. zabezpečíme.
							</p> */}
							<h1
								className="font-weight-bold text-xs-6 text-sm-12 line-height-2 mb-3  text-white"
							
							>
								Zamčené a zaboucnuté dveře
							
									<span className="d-inline-block text-primary highlighted-word highlighted-word-rotate highlighted-word-animation-1 alternative-font-3 font-weight-bold text-1 ml-2">
										24 / 7
								
								</span>
							</h1>
							<p
								className="custom-font-size-1  text-white"
							
							>
								Zabouchli jste si dveře a nevíte co s tím? <br/>
								{/* <strong className="text-uppercase">levně</strong>,{' '}
								<strong className="text-uppercase">kvalitně</strong>,{' '}
								<strong className="text-uppercase">rychle</strong>.<br /> Spolupracujeme s řadou firem, státních
								institucí a <strong>Policií ČR</strong>.{' '} */}
							</p>
						</div>
						<div
							className="col-lg-5 offset-lg-1 "
						>
							<div className="row align-items-stretch">
								<div className="d-flex col-sm-12 col-lg-6 mb-4">
									<AnchorLink
										href="#sluzby"
										className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
									>
										<div className="card-body">
											<h2 className="h4 card-title mb-1 text-4 font-weight-bold">
												OTEVŘENÍ ZABOUCHNUTÝCH DVEŘÍ
											</h2>
											<p className="card-text">Dveře otvíráme bez poškození</p>
										</div>
									</AnchorLink>
								</div>
								<div className="d-flex col-sm-12 col-lg-6 mb-4">
									<AnchorLink
										href="#sluzby"
										className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
									>
										<div className="card-body">
											<h2 className="h4 card-title mb-1 text-4 font-weight-bold">
												OTEVŘENÍ ZAMČENÝCH DVEŘÍ
											</h2>
											<p className="card-text">Pro vlastníky i nájemce</p>
										</div>
									</AnchorLink>
								</div>
								<div className="d-flex col-sm-12 col-lg-6 mb-4">
									<AnchorLink
										href="#sluzby"
										className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
									>
										<div className="card-body">
											<h2 className="h4 card-title mb-1 text-4 font-weight-bold">KOMPLETNÍ SLUŽBY</h2>
											<p className="card-text">Zalomený klíč, sjednocení vložek a další služby...</p>
										</div>
									</AnchorLink>
								</div>
								<div className="d-flex col-sm-12 col-lg-6 mb-4">
									<AnchorLink
										href="#cenik"
										className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
									>
										<div className="card-body">
											<h2 className="h4 card-title mb-1 text-4 font-weight-bold">FIXNÍ CENY NONSTOP</h2>
											<p className="card-text">Předem víte kolik zaplatíte - 24 / 7</p>
										</div>
									</AnchorLink>
								</div>
							</div>
						</div>
						<div
							className="myBar col-md-8 alternative-font-4 col-lg-6 col-xl-5 custom-header-bar py-4 pr-5  z-index-3 animated maskRight "
						
						>
							<div
								className="heroClaim fadeInRightShorter "
							>
								{/* <div className="star-rating-wrap d-flex align-items-center justify-content-center position-relative text-warning text-5 py-2 mb-2">
									<i className="fas fa-star" />
									<i className="fas fa-star ml-1" />
									<i className="fas fa-star ml-1" />
									<i className="fas fa-star ml-1" />
									<i className="fas fa-star ml-1" />
								</div> */}

								<div className="position-relative text-center font-weight-bold text-7 line-height-2 text-white mb-0">
									Co je zamčené, otevřeme.<br />Co je otevřené, zabezpečíme.
								</div>
								{/* <p className="position-relative text-center text-white font-weight-normal mb-1">
									* Přidejte se mezi naše spokojené klienty
								</p> */}
							</div>
						</div>
					</div>
				</div>
			</div>		
		</Wrapper>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query {
		img1: file(relativePath: { eq: "img.png" }) {
			childImageSharp {
				fluid(maxWidth: 920) {
					...GatsbyImageSharpFluid
				}
			}
		}
		img2: file(relativePath: { eq: "02.png" }) {
			childImageSharp {
				fluid(maxWidth: 1920) {
					...GatsbyImageSharpFluid
				}
			}
		}	
		logo1: file(relativePath: { eq: "cisa_logo.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, grayscale: true) {
					...GatsbyImageSharpFluid
				}
			}
		}
		logo2: file(relativePath: { eq: "evva_logo.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, grayscale: true) {
					...GatsbyImageSharpFluid
				}
			}
		}
		logo3: file(relativePath: { eq: "FAB_logo.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, grayscale: true) {
					...GatsbyImageSharpFluid
				}
			}
		}
		logo4: file(relativePath: { eq: "mult-lock_logo.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, grayscale: true) {
					...GatsbyImageSharpFluid
				}
			}
		}
		site {
			siteMetadata {
				email
				tel
			}
		}
	}
`;